import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "./providers";
import { App } from "./app";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
          console.error('Service Worker registration failed:', error);
      });
}

ReactDOM.render(
  <StrictMode>
    <Provider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
